import React, { Fragment } from "react";
import { FaAngleRight, FaNodeJs, FaPython, FaReact } from "react-icons/fa";
import { SiCsharp, SiPrisma } from "react-icons/si";
import {
  TbBrandBootstrap,
  TbBrandHtml5,
  TbBrandJavascript,
  TbBrandNextjs,
  TbBrandTailwind,
  TbFileTypePhp,
} from "react-icons/tb";
import Typewriter from "../Assets/TypeWriter";
import Counter from "../Counter";

const frontend = [
  { name: "JavaScript", icon: TbBrandJavascript, star: 5 },
  { name: "HTML 5", icon: TbBrandHtml5, star: 5 },
  { name: "Bootstrap", icon: TbBrandBootstrap, star: 4 },
  { name: "React.js", icon: FaReact, star: 4 },
  { name: "Tailwind CSS", icon: TbBrandTailwind, star: 3 },
  { name: "Next.js", icon: TbBrandNextjs, star: 3 },
];
const backend = [
  { name: "Node.js", icon: FaNodeJs, star: 5 },
  { name: "Prisma", icon: SiPrisma, star: 1 },
  { name: "Python", icon: FaPython, star: 4 },
  { name: "PHP", icon: TbFileTypePhp, star: 4 },
  { name: "C#", icon: SiCsharp, star: 3 },
  { name: "Next.js", icon: TbBrandNextjs, star: 3 },
];
const star = (coloured) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <span
        key={i}
        className={`inline-block h-3.5 w-3.5 ${
          i < coloured ? "bg-gradient-to-tr from-blue-800 to-blue-600" : ""
        }`}
      ></span>
    );
  }
  return <Fragment>{stars}</Fragment>;
};
const Introduction2 = () => {
  return (
    <Fragment>
      <div className="mx-auto bg-[#252734] min-w-full ">
        <div className="prose prose-invert min-w-full md:flex">
          <div className="relative flex h-screen basis-1/3 flex-col justify-between pb-24 md:h-auto md:items-center md:py-12">
            <div className="not-prose absolute top-0 left-0 h-full w-full bg-omega-900 grayscale">
              <div className="relative overflow-hidden h-full w-full">
                <div className="absolute left-0 top-0 z-10 hidden h-full w-[120%] bg-omega-900 origin-bottom skew-x-6 transform-gpu transition-transform duration-700"></div>
                <img
                  alt="my"
                  sizes="100vw"
                  src="/Images/about-image.png"
                  className="md:transform-gpu md:transition-all md:duration-700 object-cover"
                />
              </div>
              <div className="absolute top-0 left-0 z-20 h-full w-full bg-gradient-to-b from-transparent via-transparent to-black/90"></div>
            </div>
            <div className="z-10 bg-transparent p-6 text-center"></div>
            <div className="z-10 p-6 text-center md:p-8">
              <h2>Ready to discuss your project?</h2>
              <p>Let's super-charge your business today!</p>
              <a
                className="relative inline-flex items-center justify-center leading-normal no-underline group cursor-pointer select-none focus:outline-none peer md:peer-even:ml-6"
                href="/ContactMe"
              >
                <div className="z-10 h-full w-full border-4 border-transparent group-active:border-alpha flex transform-gpu transition-transform not-prose font-mono py-4 px-8 text-base hover:translate-x-2 hover:translate-y-2 bg-white text-black">
                  <span className="mx-auto">
                    <p>Get a Free Price Quote</p>
                  </span>
                </div>
                <div className="absolute h-full w-full bg-gradient-to-r from-alpha via-alpha-300 to-beta top-2 left-2"></div>
              </a>
            </div>
          </div>
          <div className="basis-2/3">
            <div className="p-6 md:p-12">
              <h3 className="mb-2">I'm Specialized in</h3>
              <h3 className="inline">
                <div className="m-0 inline-flex items-baseline font-mono">
                  <FaAngleRight className="hidden shrink-0 grow-0 self-center text-blue-700 md:block" />
                  <Typewriter
                    mainText={[
                      "Python3",
                      "PHP with Laravel",
                      "Core PHP",
                      "React.js",
                      "Node.js",
                      "Next.js",
                      "Core Javascript",
                      "Full Stack Web Development",
                    ]}
                    infinite={true}
                    delay={100}
                    className="overflow-hidden whitespace-nowrap text-blue-700"
                  />
                  <div className="ml-2 -translate-y-2 text-blue-700 animate-blink">
                    _
                  </div>
                </div>
              </h3>
            </div>
            <div className="grid grid-cols-1 items-start divide-y divide-omega-700 shadow-xl">
              <div className="bg-gradient-omega-900 p-6 md:px-12 md:py-8">
                <p className="col-span-3 mt-0 mb-6 self-center border-l-2 border-alpha pl-3 text-white">
                  Back-End Development
                </p>
                <div className="grid grid-cols-fluid gap-y-3 gap-x-8 [--tw-fluid-col-min:12rem]">
                  {backend &&
                    backend.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="md:transition-all md:duration-1000"
                        >
                          <div className="flex items-center">
                            <item.icon
                              className="mr-3 h-7 w-7  text-omega-500"
                              title={item.name}
                            />
                            <small className="font-bold">{item.name}</small>
                            <div className="ml-auto space-x-px">
                              {star(item.star)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="bg-gradient-omega-900 p-6 md:px-12 md:py-8">
                <p className="col-span-3 mt-0 mb-6 self-center border-l-2 border-alpha pl-3 text-white">
                  Front-End UI/UX Design
                </p>
                <div className="grid grid-cols-fluid gap-y-3 gap-x-8 [--tw-fluid-col-min:12rem]">
                  {frontend &&
                    frontend.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="md:transition-all md:duration-1000"
                        >
                          <div className="flex items-center">
                            <item.icon
                              className="mr-3 h-7 w-7  text-omega-500"
                              title={item.name}
                            />
                            <small className="font-bold">{item.name}</small>
                            <div className="ml-auto space-x-px">
                              {star(item.star)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="md:transition-all md:duration-1000 prose min-w-full p-6 dark:prose-invert md:p-12">
              <h5>MISHRI LAL SAHU</h5>
              <p>
                Each of these technologies has its unique strengths, and my
                experience with them has contributed to a well-rounded skill set
                for various web development tasks, backend programming, and
                building robust applications.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Counter />
      <div className="mx-auto bg-[#252734] min-w-full ">
        <div className="prose prose-invert flex-wrap min-w-full justify-between p-6 md:flex">
          <div className="flex-1 md:p-12">
            <h3 className="md:text-3xl text-xl">Experience</h3>
            <div
              className="md:transition-all md:origin-left md:duration-1000 h-1.5 bg-gradient-to-r from-blue-700
          via-beta to-alpha"
            ></div>
            <div className="mt-6 flex flex-col md:mt-12">
              <div className="flex">
                <div>
                  <h6 className="pr-1 font-medium">Undefined</h6>
                  <div>Software Engineer(MERN, Python)</div>
                </div>
                <small className="ml-auto shrink-0 opacity-60">
                  2024-Present
                </small>
              </div>
              <hr className="my-6" />
              <div className="flex">
                <div>
                  <h6 className="pr-1 font-medium">Sileo Technology Pvt. Ltd.</h6>
                  <div>PHP Developer</div>
                </div>
                <small className="ml-auto shrink-0 opacity-60">2023-2024</small>
              </div>
              <hr className="my-6" />
              <div className="flex">
                <div>
                  <h6 className="pr-1 font-medium">Softpro India Pvt. Ltd.</h6>
                  <div>Junior Software Engineer</div>
                </div>
                <small className="ml-auto shrink-0 opacity-60">2022-2023</small>
              </div>
              <hr className="my-6" />
            </div>
          </div>
          <div className="flex-1 md:p-12">
            <h3 className="md:text-3xl text-xl">Education</h3>
            <div className="md:transition-all md:origin-left md:duration-1000 h-1.5 bg-gradient-to-r from-blue-700 via-beta to-alpha"></div>
            <div className="mt-6 flex flex-col md:mt-12">
              <div className="flex">
                <div>
                  <h6 className="pr-1 font-medium">AKTU University</h6>
                  <div>B. Tech. (Computer Science & Engineering)</div>
                </div>
                <small className="ml-auto shrink-0 opacity-60">2022-2025</small>
              </div>
              <hr className="my-6" />
              <div className="flex">
                <div>
                  <h6 className="pr-1 font-medium">
                    BTEUP Board
                  </h6>
                  <div>Diploma (Computer Science & Engineering)</div>
                </div>
                <small className="ml-auto shrink-0 opacity-60">2019-2022</small>
              </div>
              <hr className="my-6" />
              <div className="flex">
                <div>
                  <h6 className="pr-1 font-medium">UP Board</h6>
                  <div>High School</div>
                </div>
                <small className="ml-auto shrink-0 opacity-60">2017-2019</small>
              </div>
              <hr className="my-6" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Introduction2;
