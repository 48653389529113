import React, { Fragment } from 'react'
import {Breadcrumb} from '../../CommonElements/AbstractElements'
import Introduction from './Introduction'
import Introduction2 from './Introduction2'

const AboutMe = () => {
  return (
    <Fragment >
      <Breadcrumb title={'About Me'} />
      <Introduction />
      <Introduction2 />
    </Fragment>
  )
}

export default AboutMe
