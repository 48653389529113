import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../CommonElements/Breadcrumb";
import { APP_URL, numberToFormalLanguage, truncateText } from "./../../utils/Constant";
import { Link } from "react-router-dom";

const Blogs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchBlog = async (page = 1, search = "") => {
    setIsLoading(true);
    try {
      const response = await fetch(`${APP_URL}/blog/app/get/filter?currentPage=${page}&search=${search}`);
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData.data);
        setPage(Number(jsonData.page)||1);
        setTotalPages(Math.ceil(jsonData.total/jsonData.limit)||1);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBlog(page, searchTerm);
  }, [page, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when searching
  };

  const handlePagination = (newPage) => {
console.log(newPage);
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };
  return (
    <React.Fragment>
      <Breadcrumb title={"Blogs"} />
      <section className="text-gray-600 bg-[#252734f5] body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="mb-6 flex justify-between items-center">
            <input
              type="text"
              className="p-2 rounded-lg bg-gray-200 text-gray-800"
              placeholder="Search Blogs"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          {isLoading ? (
            <div className="flex justify-center min-h-[200px] max-h-[400px] p-8 ">
              <img src="/loader.svg" alt="Loader" className="h-full w-auto" />
            </div>
          ) : (
            <React.Fragment>
              <div className="flex flex-wrap -m-4">
                {data &&
                  data.map((item, index) => <BlogCard key={index} item={item} />)}
              </div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePagination}
              />
            </React.Fragment>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

const Pagination = ({ page, totalPages, handlePagination }) => {
  return (
    <div className="flex justify-center mt-8">
      <button
        onClick={() => handlePagination(page - 1)}
        disabled={page === 1}
        className={`mx-2 px-3 py-1 bg-gray-700 text-white rounded-lg ${ page===1&&'disabled:opacity-50'}`}
      >
        Previous
      </button>
      <span className="text-gray-300">{`Page ${page} of ${totalPages}`}</span>
      <button
        onClick={() => handlePagination(page + 1)}
        disabled={page === totalPages}
        className={`mx-2 px-3 py-1 bg-gray-700 text-white rounded-lg ${ page === totalPages&&'disabled:opacity-50'}`}
      >
        Next
      </button>
    </div>
  );
};

export const BlogCard = (props) => {
  return (
    <div className="p-4 md:w-1/3 xl:w-1/4">
      <div className="h-full bg-[#252734] shadow-md rounded-lg overflow-hidden">
        <img
          className="lg:h-48 md:h-36 w-full object-cover object-center"
          src={`${APP_URL}/${props.item?.thumbnail}`}
          onError={(e) => (e.target.src = "/blog-thumbnail.png")}
          alt={props.item?.title}
        />
        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
            {props.item?.category}
          </h2>
          <h1 className="title-font text-lg font-medium text-white mb-3">
            {props.item.title}
          </h1>
          <p className="leading-relaxed max-h-[80px] overflow-y-clip mb-3">{truncateText(props.item?.metaDescription||'',80)}</p>
          <div className="flex items-center flex-wrap ">
            <Link
              to={`${props.item?.slug}`}
              className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>
            <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
              <svg
                className="w-4 h-4 mr-1"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              {numberToFormalLanguage(props?.item?.views || 1000)}
            </span>
            <span className="text-gray-400 inline-flex items-center leading-none text-sm">
              <svg
                className="w-4 h-4 mr-1"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
              {props?.item?.commentCount}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
