import React, { useState } from "react";

const CommentForm = ({isLoading, onSubmit, reply, replyName, setReply,setReReply ,reReply}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let valid = true;
    let newErrors = { name: "", email: "", comment: "" };

    if (!formData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }
    if (!formData.comment) {
      newErrors.comment = "Comment is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleReset=()=>{
      setFormData({ name: "", email: "", comment: "" });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if(reply){
        formData.reply = reply;
        if(reReply){
          formData.reReply=reReply;
        }
      }
      onSubmit(formData,handleReset);
    }
  };

  return (
    <div className={`${reply && "p-4 border py-0 bg-gray-800 rounded-md mt-3 border-gray-200"}`}>
      {reply && (
        <div className="flex justify-between py-3">
          <div className="text-2xl text-gray-400">Reply to <span className="text-white">@{replyName}</span></div>
          <button onClick={()=>{setReply(null);setReReply(null)}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-white"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </div>
      )}
      <form className="mb-6" onSubmit={handleSubmit}>
        <div className="md:flex md:gap-4">
          <div className={`py-2 px-4 mb-4 w-full md:w-1/2 rounded-lg rounded-t-lg border ${reply? "bg-gray-900":"bg-gray-800"} border-gray-700`}>
            <label htmlFor="name" className="sr-only">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`px-0 w-full text-sm border-0 focus:ring-0 focus:outline-none text-white placeholder-gray-400 ${reply? "bg-gray-900":"bg-gray-800"}`}
              placeholder="Write your name..."
            />
            {errors.name && (
              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
            )}
          </div>
          <div className={`py-2 px-4 mb-4 w-full md:w-1/2 rounded-lg rounded-t-lg border ${reply? "bg-gray-900":"bg-gray-800"} border-gray-700`}>
            <label htmlFor="email" className="sr-only">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`px-0 w-full text-sm border-0 focus:ring-0 focus:outline-none text-white placeholder-gray-400 ${reply? "bg-gray-900":"bg-gray-800"}`}
              placeholder="Write your email..."
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>
        </div>
        <div className={`py-2 px-4 mb-4 rounded-lg rounded-t-lg border ${reply? "bg-gray-900":"bg-gray-800"} border-gray-700`}>
          <label htmlFor="comment" className="sr-only">
            Your comment
          </label>
          <textarea
            id="comment"
            name="comment"
            rows="6"
            value={formData.comment}
            onChange={handleChange}
            className={`px-0 w-full text-sm border-0 focus:ring-0 focus:outline-none text-white placeholder-gray-400 ${reply? "bg-gray-900":"bg-gray-800"}`}
            placeholder="Write a comment..."
          ></textarea>
          {errors.comment && (
            <p className="text-red-500 text-xs mt-1">{errors.comment}</p>
          )}
        </div>
        <button disabled={isLoading||false}
          type="submit"
          className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-gray-200 bg-[#1d4ed8] rounded-lg focus:ring-4 focus:ring-[#4162be] focus:ring-primary-900 hover:bg-[#1d20d8]"
        >
          Post comment
        </button>
      </form>
    </div>
  );
};

export default CommentForm;
