import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumb, Paragraph } from "../../CommonElements/AbstractElements";
import { FiUser } from "react-icons/fi";
import { BsCalendar } from "react-icons/bs";
import Comment from "./Comment";
import { useParams } from "react-router-dom";
import { APP_URL } from "../../utils/Constant";
import { Disclosure } from "@headlessui/react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { Helmet } from "react-helmet-async"; 
import "./blog.css";

const BlogDetail = () => {
  const { slug } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(APP_URL + "/blog/app/get/" + slug);
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.data);
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBlog();
  }, [slug]);

  const format = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <Fragment>
      <Helmet>
        <title> {`MISHRI LAL SAHU | ${data?.title}`}</title>
        <meta name="description" content={data?.metaDescription || "A detailed blog about " + data?.metaTitle} />
        <meta name="keywords" content={data?.metaKeyword || "technology, coding, programming, " + data?.categoryId?.name} />
        <meta property="og:image" content={`${APP_URL}/${data?.image}`} />
        <link rel="canonical" href={`${APP_URL}/Blogs/${slug}`} />
      </Helmet>
      <Breadcrumb title={data?.title} ParentLink="/Blogs" ParentTitle="Blogs" />
      <section className="text-gray-300 bg-[#252734f5] blog">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="md:grow mb-3">
              <div className="text-slate-400 md:text-lg text-sm ">
                || {data?.categoryId?.name}
              </div>
            </div>
            <div className="text-slate-400 mb-3 flex align-middle md:text-lg text-sm">
              <span className="md:ml-4 align-middle ml-2">
                <FiUser />
              </span>
              <p className="ps-2 leading-4">By MISHRI LAL SAHU</p>
            </div>
            <div className="text-slate-400 mb-3 flex md:text-lg text-sm">
              <span className="md:ml-4 ml-2">
                <BsCalendar />
              </span>
              <p className="ps-2 leading-4">{data?.createdAt && format(data.createdAt)}</p>
            </div>
          </div>
          <div className="blog-image"><img src={`${APP_URL}/${data?.image}`}
          onError={(e) => (e.target.src = "/blog-thumbnail.png")} alt={data?.title} /></div>
          <Paragraph className="md:px-16 xl:px-8 py-8">
            <div className="prose" style={{ all: 'unset', color: 'white' }} dangerouslySetInnerHTML={{ __html: data?.content }} />
          </Paragraph>
            <Disclosure>
              {({ open }) => (
                <div className="mt-6 bg-gray-800 rounded-lg p-4 md:p-6">
                  <Disclosure.Button className="flex justify-between w-full text-white text-lg font-medium">
                    <span>Disclaimer</span>
                    <span>{open ? <FiChevronUp /> : <FiChevronDown />}</span>
                  </Disclosure.Button>
                  <Disclosure.Panel className="mt-4 text-gray-400">
                    <p>The information provided on this blog is for educational and informational purposes only and is intended for a technical audience. While we make every effort to ensure the accuracy and reliability of the content, the rapidly evolving nature of technology means that some information may become outdated or superseded. The techniques, code, and practices discussed are provided "as-is" without any warranties, express or implied. You are responsible for testing and verifying any information before applying it to your projects or systems. The author and the blog disclaim any liability for any damage, loss, or disruption caused by the application or misuse of the information provided.</p>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
        </div>
      </section>
      {data?.isComment && <Comment id={data.id} />}
    </Fragment>
  );
};

export default BlogDetail;
