import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../CommonElements/Breadcrumb";
import { APP_URL, truncateText } from "./../../utils/Constant";
import { Link } from "react-router-dom";

const Services = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchService = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(APP_URL + "/service-blog/app/get/filter");
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.data);
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchService();
  }, []);
  return (
    <React.Fragment>
      <Breadcrumb title={"Services"} />
      <section className="text-gray-600 bg-[#252734f5] body-font">
        <div className="container px-5 py-24 mx-auto">
          {isLoading ? (
            <div className="flex justify-center min-h-[200px] max-h-[400px] p-8 ">
              <img src="/loader.svg" alt="Loader" className="h-full w-auto" />
            </div>
          ) : (
            <div className="flex flex-wrap -m-4">
              {data &&
                data.map((item, index) => <ServiceCard key={index} item={item} />)}
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export const ServiceCard = (props) => {
  return (
    <div className="p-4 md:w-1/3 xl:w-1/4">
      <div className="h-full bg-[#252734] shadow-md rounded-lg overflow-hidden">
        <img
          className="lg:h-48 md:h-36 w-full object-cover object-center"
          src={`${APP_URL}/${props.item?.thumbnail}`}
          onError={(e) => (e.target.src = "/blog-thumbnail.png")}
          alt={props.item?.title}
        />
        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
            {props.item?.service}
          </h2>
          <h1 className="title-font text-lg font-medium text-white mb-3">
            {props.item.title}
          </h1>
          <p className="leading-relaxed max-h-[80px] overflow-y-clip mb-3">{truncateText(props.item?.metaDescription||'',80)}</p>
          <div className="flex items-center flex-wrap ">
            <Link
              to={`${props.item?.slug}`}
              className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>
            <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 ">
              <svg
                className="w-4 h-4 mr-1"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              1.2K
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
