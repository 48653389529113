import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumb, Paragraph } from "../../CommonElements/AbstractElements";
import { FiUser } from "react-icons/fi";
import { BsCalendar } from "react-icons/bs";
import "./service-blog.css";
import { useParams } from "react-router-dom";
import { APP_URL } from "../../utils/Constant";

const ServiceDetail = () => {
  const { slug } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await fetch(APP_URL + "/service-blog/app/get/" + slug);
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.data); // Assuming the response structure has a 'data' property
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchService();
  }, [slug]);
  const format = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  return (
    <Fragment>
      <Breadcrumb title={data?.title} ParentLink="/Services" ParentTitle="Services" />
      <section className="text-gray-600 bg-[#252734f5] blog">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="md:grow mb-3">
              <div className="text-slate-400 md:text-lg text-sm ">
                || {data?.serviceId?.name}
              </div>
            </div>
            <div className="text-slate-400 mb-3 flex align-middle  md:text-lg text-sm ">
              <span className="md:ml-4 align-middle ml-2">
                <FiUser />
              </span>
              <p className="ps-2 leading-4"> By MISHRI LAL SAHU</p>
            </div>
            <div className="text-slate-400 mb-3 flex md:text-lg text-sm ">
              <span className="md:ml-4 ml-2">
                <BsCalendar />
              </span>
              <p className="ps-2 leading-4">{data?.createdAt && format(data.createdAt)}</p>
            </div>
          </div>
          <Paragraph className="md:px-16 xl:px-8 py-8" ><div className="prose" style={{ all: 'unset',color:'white' }} dangerouslySetInnerHTML={{__html:data?.content}}/></Paragraph>
        </div>
      </section>
    </Fragment>
  );
};

export default ServiceDetail;
