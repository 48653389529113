import React, { useEffect, useState } from "react";
import CommentForm from "./CommentForm";
import { APP_URL } from "../../utils/Constant";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const format = (dateString) => {
  const options = { month: "short", day: "2-digit", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
};
const Comment = ({ id }) => {
  const [comment, setComment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const MySwal = withReactContent(Swal);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
  });
  const [reply, setReply] = useState(null);
  const [reReply, setReReply] = useState(null);
  useEffect(() => {
    fetchComment();
  }, [id]);
  const handlePagination = async () => {
    fetchComment(pagination.page + 1);
    setPagination({ ...pagination, page: pagination.page + 1 });
  };
  const fetchComment = async (isPage = null) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${APP_URL}/comment/app/get/${id}?page=${
          isPage || pagination.page
        }&limit=${pagination.limit}`
      );
      if (response.ok) {
        const jsonData = await response.json();
        setPagination({ ...pagination, total: jsonData?.total });
        if (isPage) {
          setComment([...comment, ...jsonData.data]);
        } else {
          setComment(jsonData.data);
        }
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  const handleSubmit = async (data, handleReset) => {
    setIsLoading(true);
    data.blogId = id;
    const response = await fetch(APP_URL + "/comment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const error = await response.json();
      if (error?.statusCode === 409) {
        MySwal.fire("Error!", error?.message, "error");
      } else {
        MySwal.fire(
          "Error!",
          "Something went wrong! Please refresh the page",
          "error"
        );
      }
    } else {
      MySwal.fire("Success!", "Comment added successfully", "success");
      handleReset();
      setReply(null);
      setReReply(null);
      fetchComment();
    }
    setIsLoading(false);
  };

  return (
    <section className=" bg-gray-900 py-8 lg:py-16 antialiased">
      <div className="xl:text-5xl text-2xl my-2 text-center md:my-6 md:text-4xl text-white font-bold">
        Comments
      </div>
      <div className="max-w-5xl mx-auto px-4">
        {isLoading ? (
          <div className="flex justify-center min-h-[200px] max-h-[400px] p-8 ">
            <img src="/loader.svg" alt="Loader" className="h-full w-auto" />
          </div>
        ) : (
          <>
            {comment?.map((item) => (
              <>
                <article
                  key={item?.createdAt}
                  className="p-6 text-base  rounded-lg bg-gray-900"
                >
                  <footer className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <p className="inline-flex items-center mr-3 text-sm  text-white font-semibold">
                        <img
                          className="mr-2 w-6 h-6 rounded-full"
                          src="/unknown.png"
                          alt={item?.name}
                        />
                        {item?.name}
                      </p>
                      <p className="text-sm  text-gray-400">
                        <time
                          pubdate
                          datetime="2022-02-08"
                          title="February 8th, 2022"
                        >
                          {item?.createdAt && format(item.createdAt)}
                        </time>
                      </p>
                    </div>
                  </footer>
                  <p className=" text-gray-400">{item?.comment}</p>
                  <div className="flex items-center mt-4 space-x-4">
                    <button
                      type="button"
                      onClick={() => {
                        setReply(item?.id);
                        setReReply(null);
                      }}
                      className="flex items-center text-sm  hover:underline text-gray-400 font-medium"
                    >
                      <svg
                        className="mr-1.5 w-3.5 h-3.5"
                        ariaHidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                        />
                      </svg>
                      Reply
                    </button>
                  </div>
                  {reReply === null && reply && reply === item?.id && (
                    <CommentForm
                      onSubmit={handleSubmit}
                      reply={reply}
                      replyName={item?.name}
                      setReply={setReply}
                      setReReply={setReReply}
                      key={"reply" + item?.id}
                    />
                  )}
                </article>
                {Array.isArray(item?.reply) && item?.reply?.length > 0 && (
                  <Reply
                    data={item?.reply}
                    key={"reply-section" + item?.id}
                    reReply={reReply}
                    reply={reply}
                    handleSubmit={handleSubmit}
                    setReReply={setReReply}
                    setReply={setReply}
                    name={item?.name}
                  />
                )}
              </>
            ))}
            <div className="flex my-4 justify-center">
              {comment?.length < pagination?.total && (
                <button
                  onClick={() => handlePagination()}
                  className="border-[1px] text-white border-slate-400 rounded-full text-sm p-4 px-8 flex align-middle leading-4"
                >
                  View more{" "}
                </button>
              )}
            </div>
          </>
        )}
        <CommentForm isLoading={isLoading} key={"blog-form" + id} onSubmit={handleSubmit} />
      </div>
    </section>
  );
};
const Reply = ({
  data,
  reply,
  reReply,
  setReReply,
  setReply,
  name,
  handleSubmit,
}) => {
  const [pagination, setPagination] = useState({
    page: data?.length > 2 ? 2 : data?.length,
    total: data?.length,
  });
  const getRepliedName = (array, reReplyId) => {
    const replied = array?.find((item) => item.id == reReplyId);
    return replied?.name || null;
  };
  return (
    <div className="px-4">
      {data?.map((subItem, index) => {
        if (index < pagination.page) {
          return (
            <article
              key={subItem?.createdAt}
              className=" py-2 mb-3 ml-6 lg:ml-12 text-base  rounded-lg bg-gray-900"
            >
              <footer className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <p className="inline-flex items-center mr-3 text-sm  text-white font-semibold">
                    <img
                      className="mr-2 w-6 h-6 rounded-full"
                      src="/unknown.png"
                      alt={subItem?.name}
                    />
                    {subItem?.name}
                  </p>
                  <p className="text-sm  text-gray-400">
                    <time
                      pubdate
                      datetime="2022-02-12"
                      title={subItem?.createdAt && format(subItem.createdAt)}
                    >
                      {subItem?.createdAt && format(subItem.createdAt)}
                    </time>
                  </p>
                </div>
              </footer>
              <p className=" text-gray-400">
                <span className="text-gray-200">
                  @
                  {subItem?.reReply == 0
                    ? name
                    : getRepliedName(data, subItem?.reReply) || name}{" "}
                </span>
                {subItem?.comment}
              </p>
              <div className="flex items-center mt-4 space-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setReReply(subItem?.id);
                    setReply(subItem?.reply);
                  }}
                  className="flex items-center text-sm  hover:underline text-gray-400 font-medium"
                >
                  <svg
                    className="mr-1.5 w-3.5 h-3.5"
                    ariaHidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                    />
                  </svg>
                  Reply
                </button>
              </div>
              {reply && reReply && reReply === subItem?.id && (
                <CommentForm
                  onSubmit={handleSubmit}
                  reply={reply}
                  reReply={reReply}
                  replyName={subItem?.name}
                  setReply={setReply}
                  setReReply={setReReply}
                  key={"reReply" + reply}
                />
              )}
            </article>
          );
        }
      })}
      <div className="flex px-4 justify-between">
        <div>
          {pagination.total > pagination.page && (
            <button
              onClick={() =>
                setPagination({ ...pagination, page: pagination.page + 2 })
              }
              className="flex items-center text-sm  hover:underline text-gray-400 font-medium"
            >
              view more
            </button>
          )}
        </div>
        <div>
          {pagination.page > 2 && (
            <button
              onClick={() => setPagination({ ...pagination, page: 2 })}
              className="flex items-center text-sm  hover:underline text-gray-400 font-medium"
            >
              close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comment;
