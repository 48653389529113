import React, { useState, useEffect } from "react";

const Typewriter = (props) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainIndex, setMainIndex] = useState(0);
  const [text, setText] = useState("");

  useEffect(() => {
    let timeout;

    if (Array.isArray(props.mainText)) {
      if (mainIndex < props.mainText.length) {
        if (currentIndex < text.length) {
          timeout = setTimeout(() => {
            setCurrentText((prevText) => prevText + text[currentIndex]);
            setCurrentIndex((prevIndex) => prevIndex + 1);
          }, props.delay);
        } else {
          timeout = setTimeout(() => {
            setText(props.mainText[mainIndex]);
            setMainIndex((prevMainIndex) => prevMainIndex + 1);
            setCurrentIndex(0);
            setCurrentText("");
          }, props.delay*2);
        }
      } else if (props.infinite) {
        setMainIndex(0);
      }
    } else {
      setText(props.mainText);
      if (currentIndex < text.length) {
        timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, props.delay);
      } else if (props.infinite) {
        setCurrentIndex(0);
        setCurrentText("");
      }
    }

    return () => clearTimeout(timeout);
  }, [
    props.mainText,
    props.infinite,
    currentIndex,
    text,
    props.delay,
    mainIndex,
  ]);

  return <span className={props.className}>{currentText}</span>;
};

export default Typewriter;
