import React from 'react'
import Typewriter from '../Assets/TypeWriter'

const Introduction = () => {
  return (
    <section className="introduction px-4 xl:px-10 bg-[#2a2c39] md:py-8 py-4 xl:py-10">
    <div className="container">
      <div className="grid md:grid-flow-row md:grid-cols-3 ">
       <div className="col-span-1 md:order-1 order-2 xl:col-span-2 md:p-8 md:pt-12 align-middle xl:pt-20 p-4">
          <div className="text-2xl md:text-4xl py-8  md:pb-16 text-white xl:text-5xl">My name is MISHRI LAL SAHU and I'm <Typewriter
                className="text-[#1d4ed8] "
                mainText={[
                  "Single",
                  "Good Student",
                  "Full Stack Web Developer",
                  "App Developer",
                  "Ethical Hacker",
                  "Blogger",
                ]}
                delay={80}
                infinite={true}
              /></div>
              <div className="md:text-xl text-justify text-[#a7a8ad]">
                  My expertise lies in using technologies such as PHP, Python3, Django, React Js, Node Js, Express Js, Next Js, Tailwind Css,MySQL, MongoDB, Laravel and CodeIgniter (4,3) to create robust, scalable, and dynamic web applications. With a deep passion for problem-solving and a keen eye for detail, I thrive in challenging environments and am always seeking to expand my skills set to tackle new and exciting projects. Throughout my experience, I have developed a strong understanding of web development best practices and a knack for finding efficient solutions to complex problems.
              </div>
       </div>
       <div className="col-span-1 md:order-2 order-1 text-center justify-center relative w-full py-0 px-8 md:p-12">
         <img alt="Mishri Lal Sahu" className=' max-h-[26rem]  mx-auto h-auto' src='/Images/mishri-lal-sahu-profile.png' />
       </div>
      </div>
    
    </div>
    </section>
  )
}

export default Introduction
